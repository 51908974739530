<template>
  <a-card :bordered="false" class="card-area">

    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="5">
           <a-form-item label="标题">
              <a-input v-model="queryParams.title" placeholder="标题" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="年份">
              <a-select
                show-search
                placeholder="年份"
                v-model="queryParams.year"
                style="width: 100%"
              >
                <a-select-option
                  v-for="t in threePillarsYears"
                  :key="t"
                  :value="t"
                  >{{ t }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
				<a-row>
				  <a-col :span="12"></a-col>
				  <a-col :span="12" :style="{ textAlign: 'right' }">
				    <a-button type="primary" @click="search">查询</a-button>
				    <a-button style="margin-left: 8px" @click="reset">重置</a-button>
				  </a-col>
				</a-row>
      </a-form>
    </div>

    <div class="operator">
      <a-button @click="addRecord">新增</a-button>
      <a-button @click="batchDelete">删除</a-button>
    </div>
    
    <a-table
      ref="TableInfo" 
      bordered 
      :loading="loading" 
      :columns="columns" 
      :data-source="dataSource" 
      :rowKey="(record) => record.id"
      :pagination="pagination" 
      @change="handleTableChange"
      :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
    >
      <template slot="enclosure" slot-scope="text">
        <a :href="text">{{text}}</a>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editRecord(record)" title="修改"></a-icon>
      </template>
    </a-table>
    <three-zhizhu-add ref="threeZhizhuAdd" @close="handleAddClose" @success="handleAddSuccess" :addVisiable="addVisiable" ></three-zhizhu-add>
    <three-zhizhu-edit ref="threeZhizhuEdit" @close="handleEditClose" @success="handleEditSuccess" :editVisiable="editVisiable" ></three-zhizhu-edit>
  </a-card>
</template>

<script>
import ThreeZhizhuAdd from "./ThreeZhizhuAdd"
import ThreeZhizhuEdit from "./ThreeZhizhuEdit"

const columns = [
  {
    title: "标题",
    dataIndex: "title",
    width: 300
  },
  {
    title: "三支柱链接",
    dataIndex: "enclosure",
    scopedSlots: { customRender: 'enclosure' },
    width: 900
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    width: 200
  },
  {
    title: "修改时间",
    dataIndex: "updateTime",
    width: 200
  },
  
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: 'operation' },
    fixed: "right",
  }
];

export default {
  name: "ThreeZhizhu",
  components: {
    ThreeZhizhuAdd,
    ThreeZhizhuEdit
  },
  data() {
    return {
      columns,
      dataSource: [],
      threePillarsYears: [],
      selectedRowKeys: [],
      queryParams: {
        title: undefined,
        year: undefined
      },
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false,
      addVisiable: false,
      editVisiable: false
    }
  },
  mounted() {
    this.$get("three-zhizhu/year").then((r) => {
      this.threePillarsYears = r.data.data;
      this.fetch();
    });
  },
  methods: {
    addRecord() {
      this.addVisiable = true;
    },
    editRecord(record) {
      this.editVisiable = true;
      this.$refs.threeZhizhuEdit.setFormFields(record);
    },
    onSelectChange (selectedRowKeys) {
      // console.log("selectedRowKeys==>", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    search () {
      this.fetch({
        ...this.queryParams
      })
    },
    fetch(params = {}) {
      this.loading = true;
      if(this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      this.$get("three-zhizhu", {...params})
        .then(r => {
          console.log("分页加载到的三支柱文件列表 ==> ", r.data);
          const pagination = { ...this.pagination };
          pagination.total = r.data.total;
          let rows = r.data.rows;
          this.dataSource = rows;
          this.pagination = pagination;
          this.loading = false;
        })
    },
    handleAddClose() {
      this.addVisiable = false;
    },
    handleAddSuccess() {
      this.addVisiable = false;
      this.$message.success("添加三支柱文件相关成功！");
      this.fetch();
    },
    handleEditClose() {
      this.editVisiable = false;
    },
    handleEditSuccess() {
      this.editVisiable = false;
      this.$message.success("修改三支柱文件相关成功！");
      this.fetch();
    },
    batchDelete() {
      if(!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
      } else {
        let that = this;
        that.$confirm({
          title: "确定删除所选中的记录？",
          content: "当您点击确定按钮后，这些记录将会被彻底删除",
          centered: true,
          onOk () {
            let ids = that.selectedRowKeys.join();
            that.$delete(`three-zhizhu/${ids}`)
              .then(() => {
                that.$message.success("删除成功！");
                that.selectedRowKeys = [];
                that.fetch();
              })
              .catch(e => console.log("三支柱文件删除失败！==> ", e));
          },
          onCancel () {
            that.selectedRowKeys = [];
          }
        })
      }
    },
		reset() {
		  // 取消选中
		  this.selectedRowKeys = [];
		  // 重置分页
		  this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
		  if (this.paginationInfo) {
		    this.paginationInfo.current = this.pagination.defaultCurrent;
		    this.paginationInfo.pageSize = this.pagination.defaultPageSize;
		  }
		  // 重置列过滤器规则
		  this.filteredInfo = null;
		  // 重置列排序规则
		  this.sortedInfo = null;
		  // 重置查询参数
		  this.queryParams = {
		    title: undefined,
		  },
		  this.fetch();
		},
  }

}
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>